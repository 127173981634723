const firebaseConfig = {
	apiKey: 'AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo',
	authDomain: 'webs-58a8d.firebaseapp.com',
	projectId: 'webs-58a8d',
	storageBucket: 'webs-58a8d.appspot.com',
	messagingSenderId: '730684174767',
	appId: '1:730684174767:web:1b2d65dc278677ef0c3f5a',
	measurementId: 'G-HG9T040QK8',
}

export default firebaseConfig
